html {
  height: -webkit-fill-available;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  line-height: 1.6;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

b, strong {
  font-weight: bold;
}

.ohidden {
  overflow: hidden;
}

.motion {
  right: 0;
  left: 0;
  position: absolute;
}

.motion-backdrop {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

.btn-nav {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
  background: white;
  z-index: 2;

  -webkit-box-shadow: 0px 2px 23px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 23px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 23px -12px rgba(0, 0, 0, 0.75);
}

.background {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 100vh;
  background: #fff;
  -webkit-box-shadow: -12px 0px 22px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -12px 0px 22px -19px rgba(0, 0, 0, 0.75);
  box-shadow: -12px 0px 22px -19px rgba(0, 0, 0, 0.75);
  top: 0;
  right: -500px;
  width: 250px;
}

.career-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  font-weight: 300;
  position: relative;
  overflow: hidden;

  thead {
    tr {
      font-weight: bold;

      &:hover {
        background: transparent;
        cursor: default;
        color: #000;
      }
    }
  }

  tbody {
    border: 1px solid #ccc;

    tr {
      //display: table-row;
      width: 100%;
      padding-left: 10px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      overflow: hidden;
      position: relative;

      &:nth-child(odd) {
        &:before {
          border-bottom: 1px solid #eee;
        }
      }

      &:before {
        z-index: 10;
        background: transparent;
        border-bottom: 1px solid #ccc;
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: calc(100% - 1px);
      }

      &:hover {
        background: #0b9cd8;
        color: #fff;

        &:before {
          background: #0b9cd8;
          border-bottom: 1px solid #0b9cd8;
        }
      }

      &:first-child {
        border-left: 5px solid transparent;
      }


      &.career-job-active {
        &:before {
          background: #152C53;
        }
      }

      &:nth-child(odd) {
        border-bottom: 1px solid #eee;
      }

    }

    td {
      padding: 5px 15px;

      &:first-child {
        display: block;
      }
    }
  }


}

.career-detail-info {
  font-weight: 300;

  p {
    margin-top: 0.7rem;
    margin-bottom: 0;
  }
}

.career-detail-content {
  font-weight: 300;
  color: #0E1E35;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 100%;
    background: #152C53;
    bottom: 10px;
  }

  ul {
    list-style: none;
    padding-left: 15px;

    &:last-of-type {
      margin: 0;
    }

    li {
      margin-bottom: 5px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: -36px;
        top: 5px;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-size: 15px;
        display: block;
        background-image: url("../../icons/circle.svg");
      }
    }
  }
}

.block {
  display: block !important;
}

@-webkit-keyframes blur-text {
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
}

@keyframes blur-text {
  0% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
  }
  100% {
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
}